import React from 'react'
import LinkProductsProjectsScreen from './LinkProductsProjectsScreen'

const submitApi = async (
  datalabFacade,
  productName,
  podId,
  { addedLinks, removedLinks }
) => datalabFacade.updateProductLinks({
  podId,
  productName,
  projectIds: {
    add: addedLinks,
    remove: removedLinks,
  },
})

/**
 * Allows selecting projects to be linked with a product
 * @param {object} props - Component props
 * @returns {React.JSX.Element} a LinkProductsScreen component
 */
export default function LinkProductsScreen(props) {
  return (
    <LinkProductsProjectsScreen
      target="product"
      targetPrimaryKey="name"
      targetLinkKey="productName"
      counterpartLinkKey="projectId"
      counterpartCollectionName="projects"
      counterpartCollectionPrimaryKey="uuid"
      counterpartCollectionFilters={[{ property: 'status', condition: '$eq', value: 'OPEN' }]}
      selectedLinksMessage="Analysts in projects listed here will be granted read-only access to this products data"
      submitApi={submitApi}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

