/* eslint-disable react/prop-types -- plan to use TS instead MADIP-13643 */
import React, { useState } from 'react'
import {
  Button,
  Typography,
  Alert,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import TransferList from '../../components/TransferList'
import SkeletonForm from '../../components/SkeletonForm'
import ArrayAttributeRow from '../../components/ArrayAttributeRow'
import { useSnackbars } from '../../hooks/useSnackbars'
import { useFeatures } from '../../hooks/useFeatures'

/**
 * Allows selecting links (projects or products) to be linked with a target
 * (project or product). The component is used for creating UIs in both
 * directions: Link many products to a single project or many projects to a
 * single product. See LinkProductsScreen, LinkProjectsScreen.
 * @param {object} props - The component props
 * @returns {React.JSX.Element} a LinkProductsScreen component
 */
function LinkProductsProjectsScreen(props) {
  const {
    user,
    datalabFacade,
    // The target resource 'product' | 'project' is the resource that will have
    // links updated. If target is 'product' then the component allows to update
    // multiple projects for that product and vice versa. The opposite of the
    // target is referred to as the 'counterpart' for the sake of this code.
    target,
    targetPrimaryKey,
    targetLinkKey,
    counterpartLinkKey,
    counterpartCollectionName,
    counterpartCollectionPrimaryKey,
    counterpartCollectionFilters = [],
    selectedLinksMessage,
    submitApi,
  } = props

  const [loading, setLoading] = useState(true)
  // The existing links with the counterpart. If the target is 'product' this is
  // an array of all project IDs the product has existing links with when the
  // component was loaded.
  const [existingLinks, setExistingLinks] = useState([])
  // The state of links with the counterpart after the user has provided input.
  // If the target is 'product' this is an array of all project IDs the product
  // is linked to after the user has provided input to the component.
  const [updatedLinks, setUpdatedLinks] = useState([])
  // All possible counterparts the target could be linked with including already
  // linked with. If the target is 'product' all counterparts are all project
  // IDs that could possibly be linked to.
  const [allCounterparts, setAllCounterparts] = useState([])
  const { [targetPrimaryKey]: targetId } = useParams()
  const { showSnackbarInProgress } = useSnackbars()
  const { setConfirm } = useFeatures()

  const load = async () => {
    const [existingLinksResult, allCounterpartsResult] = await Promise.all([
      datalabFacade.list({
        collectionName: 'projectProductLinks',
        podId: user.pod,
        fields: [counterpartLinkKey],
        filters: [{
          property: targetLinkKey, condition: '$eq', value: targetId,
        }],
      }),
      datalabFacade.list({
        collectionName: counterpartCollectionName,
        podId: user.pod,
        fields: [counterpartCollectionPrimaryKey],
        filters: counterpartCollectionFilters,
      }),
    ])
    const existingLinkNames = existingLinksResult.data.map((existingLink) => existingLink[counterpartLinkKey])
    setExistingLinks(existingLinkNames)
    setUpdatedLinks(existingLinkNames)
    setAllCounterparts(
      allCounterpartsResult.data.map((counterpart) => counterpart[counterpartCollectionPrimaryKey])
    )
    setLoading(false)
  }

  React.useEffect(() => {
    load()
  }, [])

  const handleSubmit = () => {
    const addedLinks = updatedLinks.filter((e) => !existingLinks.includes(e))
    const removedLinks = existingLinks.filter((e) => !updatedLinks.includes(e))
    setConfirm({
      message:
        'Please confirm your changes',
      callback: async () => {
        await submitApi(datalabFacade, targetId, user.pod, { updatedLinks, addedLinks, removedLinks })
        showSnackbarInProgress(
          `Update linked ${counterpartCollectionName} (${targetId}) in progress...`
        )
      },
      additionalOptions: {
        redirect: -1,
        additionalComponent: (
          <>
            <Alert severity="warning">
              {
              `You are granting access to ${target} ${targetId} for the ${counterpartCollectionName} listed. Once applied the project(s) will have full read access to all data in the product(s) storage location.`
              }
            </Alert>
            <Typography variant="subtitle2" component="h3">
              Total before:
              {' '}
              {existingLinks.length}
            </Typography>
            <Typography variant="subtitle2" component="h3">
              Total after update:
              {' '}
              {updatedLinks.length}
            </Typography>
            <ArrayAttributeRow
              header="Additions"
              columnHeaders={[{ name: `Additions (${addedLinks.length})`, key: 'added' }]}
              keyValuePairs={addedLinks.map((e) => ({ added: e }))}
              rowKey="added"
            />
            <ArrayAttributeRow
              header="Removals"
              columnHeaders={[{ name: `Removals (${removedLinks.length})`, key: 'removed' }]}
              keyValuePairs={removedLinks.map((e) => ({ removed: e }))}
              rowKey="added"
            />
          </>),
      },
    })
  }

  return (
    loading ? <SkeletonForm />
      : (
        <>

          <Typography variant="body1" component="h2" style={{ marginLeft: '20px' }}>
            {`Select ${counterpartCollectionName} to link with ${target}: ${targetId}`}
          </Typography>

          <div className="action-button-container">
            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </div>

          <div style={{ overflow: 'auto', padding: '20px' }}>
            <TransferList
              choicesLabel={`Unlinked ${counterpartCollectionName}`}
              chosenLabel={`Linked ${counterpartCollectionName}`}
              searchBoxLabel={`Search unlinked ${counterpartCollectionName}`}
              searchBoxSelectedLabel={`Search linked ${counterpartCollectionName}`}
              selectedMessage={selectedLinksMessage}
              leftData={allCounterparts.filter((p) => !existingLinks.includes(p))}
              rightData={existingLinks}
              setChosenDataCallback={(data) => setUpdatedLinks(data)}
            />
          </div>
        </>
      )
  )
}

export default asBaseScreen(LinkProductsProjectsScreen)
