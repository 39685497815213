import React from 'react';
import flow from 'lodash.flow';
import {
    Button,
    Typography,
    TextField,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Paper,
    Container
} from "@mui/material";
import asBaseScreen from '../../screenWrappers/BaseScreen';
import DataService from '../../dataService/DataService';
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features';
import DeleteIcon from '@mui/icons-material/Delete';

//Screen requires the following data providers and features injected
const wrap = flow([
    withConfirmFeature,
    withSnackbarsFeature,
    asBaseScreen
])

class AddRPackageScreen extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.datalabFacade = props.datalabFacade;
        this.dataService = new DataService();
        this.state = { selectedPackages: [], inputPackage: '' }

    }

    handleSubmit = async () => {
        if (this.state.selectedPackages.length > 10) {
            this.props.showSnackbarWarning(`A maximum of 10 packages can be added at a time`);
        } else {
            this.props.askForConfirmationListener(
                `Are you sure you want to add these R package(s) to the Approved Repository? Any dependencies of the selected packages will also be added, you are responsible for verifying dependencies`,
                async () => {
                    await this.datalabFacade.addRPackages(this.state.selectedPackages);
                    this.props.showSnackbarInProgress(`Add R Packages (${this.state.selectedPackages.join(', ')}) in progress...`);
                },
                {
                    redirect: `/packageManagement`
                });
        }
    }

    setSelectedPackages = () => {
        const newPackages = this.state.inputPackage.split(',').map(element => element.trim()).filter(element => element.length > 0 && !this.state.selectedPackages.includes(element));
        const updatedPackages = this.state.selectedPackages.concat(newPackages);
        document.getElementById('searchBox-rPackage').focus();
        this.setState({ selectedPackages: updatedPackages, inputPackage: '' })
    }

    removePackage = (packageToRemove) => {
        const updatedPackages = this.state.selectedPackages.filter(item => item !== packageToRemove);
        this.setState({ selectedPackages: updatedPackages })
    }

    handleClearInput = () => {
        this.setState ({
            inputPackage: ''
        });
    };

    render() {
        return (
            <Container maxWidth="md">
                <Typography variant="body1" component="h2">
                    Select R packages to add to the Approved Repository
                </Typography>

                <Typography variant="body2" component="p">
                    You can find the comprehensive list of R packages including dependencies on the
                    <Link href="https://cran.r-project.org/web/packages/available_packages_by_name.html" target="_blank" rel="noreferrer">
                        &nbsp;CRAN website&nbsp;
                    </Link>
                </Typography>

                <div style={{
                    width: "100%",
                    padding: "10px 10px 10px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <TextField
                        id="searchBox-rPackage"
                        sx={{
                            width: "100%",
                            padding: "10px 10px 10px 0px"
                        }}
                        value={this.state.inputPackage}
                        placeholder="Package name, or package names separated by a comma"
                        autoFocus
                        onChange={(event) => {
                            this.setState({ inputPackage: event.target.value })
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.setSelectedPackages.bind(this)()
                            }
                        }}
                    />
                    <Button variant="text" color='primary' onClick={this.handleClearInput}>
                        Clear
                    </Button>
                    <Button variant="outlined" color='primary' disabled={this.state.inputPackage === ''} onClick={this.setSelectedPackages.bind(this)}>
                        Add
                    </Button>
                </div>


                <List>
                    {this.state.selectedPackages.map(selectedPackage =>
                        <Paper key={selectedPackage} style={{ margin: '10px' }}>
                            <ListItem>
                                <ListItemText
                                    primary={selectedPackage}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => this.removePackage.bind(this)(selectedPackage)}
                                        edge="end"
                                        aria-label="remove"
                                        size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Paper>
                    )}
                </List>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button type="submit" variant="contained" color='primary' disabled={this.state.selectedPackages.length === 0} onClick={this.handleSubmit.bind(this)}>
                        Commit Packages
                    </Button>
                </div>

            </Container>
        );
    }

}

export default wrap((AddRPackageScreen))
