import { PublicClientApplication } from '@azure/msal-browser'
import jwtDecode from 'jwt-decode'
import { getRuntimeConfig } from './Config'

const config = getRuntimeConfig()

// MS Graph scopes are a special case - they dont require the app URI prefixed to the scope
const AZURE_AD_SCOPES = ['user.read']
const appId = config.AZURE_APPLICATION_ID
const authority = config.AZURE_AD_AUTHORITY_URL

/**
 * This class handles authentication to Azure AD, including retrieving tokens for calling APIs
 *
 * The access token used to call an API is cached by MSAL in local storage
 *
 * @author Ryan Magor
 */
class AuthenticationHandler {
  constructor(props) {
    this.authChangeCallback = props.authChangeCallback

    this.publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: appId,
        authority,
        redirectUri: `${window.location.origin}/aad-callback`,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    })

    this.publicClientApplication.handleRedirectPromise().then((authResult) => {
      if (authResult) {
        this.getAuthenticated(authResult.account).then((account) => {
          this.authChangeCallback(account)
        })
      } else {
        this.getAuthenticated().then((account) => {
          if (!account) {
            this.login()
          }
          this.authChangeCallback(account)
        })
      }
    }).catch((error) => {
      this.authChangeCallback(null, error)
    })
  }

  /**
   * Redirects the user to the MS login page.
   * Any code after loginRedirect is called will not execute.
   * publicClientApplication.handleRedirectPromise defined in class constructor will
   * handle the promise returned after login redirect.
   * @returns void
   */
  login() {
    const scopes = AZURE_AD_SCOPES
    scopes.push(config.SCOPE_INVOKE)
    this.publicClientApplication.loginRedirect(
      {
        authority,
        scopes,
        prompt: 'select_account',
      }
    )
  }

  /**
   * Logs out the user
   * @returns void
   */
  // eslint-disable-next-line class-methods-use-this
  logout() {
    // There is a current issue with the V2 of the logout endpoint(which msal uses) This prevents us
    // from automatically signing out the user without their interaction see
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/735
    //* *Update as of 10/05/21 - silent logout is still intentionally unsupported, so we must
    //  continue to use the v1 workaround.** As a workaround until the issue is fixed we can use the
    //  V1 endpoint

    // V2 method:
    // this.userAgentApplication.logout();

    // V1 workaround
    localStorage.clear()
    window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout'
  }

  /**
   * Gets the signed in user account with App Roles.
   * @param account user account object. Optional, if undefined or null will retrieve any current
   * account in use.
   * @returns {import('@azure/msal-common').AccountInfo} The signed in user account object returned
   * by MSAL with App Roles appended, or null if no signed in user.
   */
  async getAuthenticated(account) {
    return this.acquireToken(account).then((tokenResponse) => {
      if (tokenResponse && tokenResponse.account) {
        const accountInfo = tokenResponse.account
        if (tokenResponse.accessToken) {
          const decoded = jwtDecode(tokenResponse.accessToken)
          accountInfo.roles = decoded.roles
          accountInfo.pod = window.localStorage.getItem('sysadmin.pod_override') ?? decoded.department
        }
        return accountInfo
      }
      return null
    })
  }

  /**
   * Gets the currently active user account to be used for calling aqcuireTokenSilent.
   * Account object returned from this function does not include user's app roles.
   * @returns {import('@azure/msal-common').AccountInfo} account object or null if no active account.
   */
  getActiveUserAccount() {
    let account = null
    account = this.publicClientApplication.getActiveAccount()
    if (account === null) {
      const accounts = this.publicClientApplication.getAllAccounts()
      if (accounts === null || accounts.length === 0) {
        return null
      }
      return accounts[accounts.length - 1]
    }
    return account
  }

  /**
   * Silently acquires an access token used for calling APIs.
   * @returns accessToken to be passed in to API calls by DatalabFacade.
   */
  async getAccessToken() {
    return this.acquireToken().then((tokenResponse) => {
      if (!tokenResponse?.accessToken) {
        return this.login()
      }
      return tokenResponse.accessToken
    })
  }

  /**
   * Retrieves token oauth 2.0 token silently, with or without a user account object supplied.
   * @param {*} account optional. If undefined or null, will retrieve current signed in account for
   * token acquisition.
   * @returns tokenResponse
   */
  async acquireToken(account) {
    if (!account || account === null) {
      const fetchedAccount = this.getActiveUserAccount()
      if (!fetchedAccount) {
        return this.login()
      }
      return this.getTokenSilent(fetchedAccount)
    }
    return this.getTokenSilent(account)
  }

  /**
   * Calls MSAL 2.x acquireTokenSilent and attempts to retrieve auth token silently from cache, or
   * acquire a new token using the refresh token. If app consent is required, a popup will show for
   * the user to consent.
   * @param {*} account signed in user's account to retrieve token for.
   * @returns tokenResponse
   */
  async getTokenSilent(account) {
    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    function returnTokenResponse(tokenResponse) {
      return tokenResponse
    }
    return this.publicClientApplication.acquireTokenSilent({
      scopes: [config.SCOPE_INVOKE],
      account,
    }).then(returnTokenResponse).catch((error) => this.authChangeCallback(null, error))
  }
}

export default AuthenticationHandler
