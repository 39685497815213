import React from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Button,
  Container,
  Chip,
  Paper,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import SkeletonForm from '../../components/SkeletonForm'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withConfirmFeature, withLoadingFeature, withSnackbarsFeature } from '../../screenWrappers/Features'
import { withAllTags, withAllProducts } from '../../screenWrappers/DataProviders.js'

// Screen requires the following data providers and features injected
const wrap = flow([
  withAllTags,
  withAllProducts,
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5, 0.25),
}))

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 *
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 * Handles both new and updating scenarios, updating boolean prop passed to component
 */
export class NewProductScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      loading: true,
      selectableTagKeys: undefined,
      selectableTagValues: undefined,
      selectedTagKey: null,
      selectedTagValue: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading && this.props.tags && (this.props.updating ? this.props.productData : true)) {
      const selectableTagKeys = this.props.tags.map((tag) => tag.tagKey)

      this.setState({
        selectableTagKeys,
        loading: false,
      })
    }
  }

  handleSubmitNew = (values) => {
    this.props.askForConfirmationListener(
      'Are you sure you want to create this Product?',
      async () => {
        values.podId = this.props.user.pod
        await this.props.datalabFacade.createProduct(values)
        this.props.showSnackbarInProgress(`Create Product (${values.name}) in progress...`)
      },
      { redirect: '/products' }
    )
  }

  handleSubmitUpdate = (values) => {
    const product = {
      name: values.name,
      tags: values.tags,
    }

    this.props.askForConfirmationListener(
      'Are you sure you want to update this Product?',
      async () => {
        product.podId = this.props.user.pod
        await this.props.datalabFacade.updateProduct(product)
        this.props.showSnackbarSuccess(`Update Product (${product.name}) succeeded`)
      },
      { redirect: '/products' }
    )
  }

  render() {
    return (
      this.state.loading
        ? <SkeletonForm />
        : (
          <Container maxWidth="md">

            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                name: this.props.updating ? this.props.productData.name : '',
                displayName: this.props.updating ? this.props.productData.displayName : '',
                tags: this.props.updating ? (!this.props.productData.tags ? [] : this.props.productData.tags) : [],
              }}
              onSubmit={(values) => {
                this.props.updating ? this.handleSubmitUpdate(values) : this.handleSubmitNew(values)
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .trim()
                  .matches(/^(?=.{3,63}$)[a-z0-9]+(-[a-z0-9]+)*$/, 'May only contain lower case characters, hyphens and numbers. Hyphens cannot be at the start or end of the name, or adjacent to another hyphen')
                  .min(3)
                  .max(63)
                  .required()
                  .label('Short name'),
                displayName: Yup.string()
                  .trim()
                  .min(3)
                  .max(200)
                  .required()
                  .label('Long name'),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                } = props

                return (
                  <form onSubmit={handleSubmit}>
                    <div>

                      <p className="mandatory-info">All fields are required</p>

                      <Grid container spacing={3} alignItems="center" direction="column">
                        <Grid container className="formRow" spacing={1} alignItems="center" justifyContent="center" direction="row">
                <Grid item xs={2}>
                                  <InputLabel htmlFor="name">Short name</InputLabel>
                                </Grid>
                <Grid item xs>
                                  <FormControl fullWidth>
                                    <TextField
                                        id="name"
                                        aria-describedby="helperName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name.trimStart()}
                                        placeholder="madip"
                                        disabled={this.props.updating}
                                      />
                                    <FormHelperText
                                        id="helperName"
                                        error={errors.name && touched.name}
                                      >
                                        {(errors.name && touched.name) ? errors.name
                                              : 'The identifier of the Product, this will also be the folder name in Azure Storage Explorer'}
                                      </FormHelperText>
                                  </FormControl>
                                </Grid>
              </Grid>

                        <Grid container className="formRow" spacing={1} alignItems="center" justifyContent="center" direction="row">
                <Grid item xs={2}>
                                  <InputLabel htmlFor="displayName">Long name</InputLabel>
                                </Grid>
                <Grid item xs>
                                  <FormControl fullWidth>
                                    <TextField
                                        id="displayName"
                                        aria-describedby="helperDisplayName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.displayName.trimStart()}
                                        placeholder="Multi-Agency Data Integration Project"
                                        disabled={this.props.updating}
                                      />
                                    <FormHelperText
                                        id="helperDisplayName"
                                        error={errors.displayName && touched.displayName}
                                      >
                                        {(errors.displayName && touched.displayName)
                                              ? errors.displayName
                                              : 'The display name of this Product'}
                                      </FormHelperText>
                                  </FormControl>
                                </Grid>
              </Grid>

                        <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                                  <Grid item xs={2}>
                                    <InputLabel htmlFor="selectedTagKey">Select Tag</InputLabel>
                                  </Grid>
                                  <Grid item xs>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                              id="selectedTagKey"
                                              blurOnSelect
                                              options={this.state.selectableTagKeys}
                                              value={this.state.selectedTagKey}
                                              renderInput={(params) => (
                                                <TextField {...params} label="Tag Name" variant="outlined" fullwidth />
                                              )}
                                              onChange={(event, value) => {
                                                if (value) {
                                                  setFieldValue('selectedTagKey', value)
                                                  setFieldValue('selectedTagValue', '')

                                                  this.setState({
                                                    selectedTagKey: value,
                                                    selectedTagValue: '',
                                                    selectableTagValues: this.props.tags.find((t) => t.tagKey === value).tagValues
                                                      .filter((t) => !values.tags.some((tag) => tag.tagKey === value && tag.tagValue === t)),
                                                  })
                                                } else {
                                                  setFieldValue('selectedTagKey', '')
                                                  setFieldValue('selectedTagValue', '')
                                                  this.setState({
                                                    selectedTagKey: '',
                                                    selectedTagValue: '',
                                                    selectableTagValues: [],
                                                  })
                                                }
                                              }}
                                            />
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <InputLabel htmlFor="selectedTagValue">Select Tag Value</InputLabel>
                                  </Grid>
                                  <Grid item xs>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                              id="selectedTagValue"
                                              blurOnSelect
                                              disabled={!this.state.selectedTagKey}
                                              options={this.state.selectableTagValues}
                                              value={this.state.selectedTagValue}
                                              renderInput={(params) => (
                                                <TextField {...params} label="Tag Value" variant="outlined" fullwidth />
                                              )}
                                              onChange={(event, value) => {
                                                setFieldValue('selectedTagValue', value)
                                                this.setState({
                                                  selectedTagValue: value,
                                                })
                                              }}
                                            />
                                      </FormControl>
                                  </Grid>
                                  <Grid item xs>
                                    <Button
                                        variant="outlined"
                                        disabled={!this.state.selectedTagKey || !this.state.selectedTagValue}
                                        onClick={() => {
                                              values.tags.push({
                                                tagKey: this.state.selectedTagKey,
                                                tagValue: this.state.selectedTagValue,
                                              })
                                              setFieldValue('selectedTagKey', '')
                                              setFieldValue('selectedTagValue', '')
                                              values.selectedTagKey = ''
                                              values.selectedTagValue = ''
                                              this.setState({
                                                selectedTagKey: '',
                                                selectedTagValue: '',
                                                selectableTagValues: [],
                                              })
                                            }}
                                      >
                                            Add&nbsp;&nbsp;
                                        <i className="fas fa-user-plus" />
                                      </Button>
                                  </Grid>
                                </Grid>
                <Grid container className="formRow" spacing={1} alignItems="center" justify="center" direction="row">
                                  <Grid item xs={2}>
                                    <InputLabel htmlFor="selectedTags">Tags Added:</InputLabel>
                                  </Grid>
                                  <Grid item xs>
                                    <Paper variant="outlined" id="selectedTags">
                                        {values.tags.map((tag) => (
                                              <StyledChip
                                                key={`${tag.tagKey}_${tag.tagValue}`}
                                                label={`${tag.tagKey}: ${tag.tagValue}`}
                                                onDelete={(delTag) => {
                                                    values.tags.splice(values.tags.indexOf(tag), 1)
                                                    setFieldValue('selectedTagKey', '')
                                                    setFieldValue('selectedTagValue', '')
                                                    values.selectedTagKey = ''
                                                    values.selectedTagValue = ''
                                                    this.setState({
                                                      selectedTagKey: '',
                                                      selectedTagValue: '',
                                                      selectableTagValues: [],
                                                    })
                                                  }}
                                              />
                                            ))}
                                      </Paper>
                                  </Grid>
                                </Grid>
              </Grid>

                      </Grid>

                      <div className="action-button-container">
                        <Button type="submit" variant="contained" color="primary">
                Submit
                              </Button>
                      </div>

                    </div>
                  </form>
                )
              }}
            </Formik>

          </Container>
        )
    )
  }
}

export default wrap(NewProductScreen)
