import React, {
  useState,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import {
  Grid,
  Button,
  Typography,
  Paper,
  Chip,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import ArrayAttributeRow from '../../components/ArrayAttributeRow'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withUser } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import ComplexAttributeRow from '../../components/ComplexAttributeRow'
import { generateInitialAzureAdPassword } from '../../util/PasswordUtils'
import SkeletonActionButtons from '../../components/SkeletonActionButtons'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'
import { Role } from '../../enums/Role'
import TempPasswordDialog from '../../components/TempPasswordDialog'
import DatalabFacade from '../../dataService/DatalabFacade'
import {
  tagShape,
  appUserShape,
  existingUserShape,
} from '../../propTypeShapes'

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  minHeight: '50vh',
  backgroundColor: '#fafafa',
})

const SectionScrollable = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '50vh',
  overflowY: 'auto',
})

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withUser,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const propTypes = {
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  tags: PropTypes.arrayOf(tagShape),
  user: appUserShape.isRequired,
  userData: existingUserShape,
  askForConfirmationListener: PropTypes.func.isRequired,
  showSnackbarInProgress: PropTypes.func.isRequired,
  showSnackbarSuccess: PropTypes.func.isRequired,
}

const defaultProps = {
  tags: undefined,
  userData: undefined,
}

/**
 * Displays user details in simple list view
 */
export function UserDetailsScreen({
  datalabFacade,
  user,
  userData,
  askForConfirmationListener,
  showSnackbarInProgress,
  showSnackbarSuccess,
}) {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState(undefined)
  const [tempPassword, setTempPassword] = useState(undefined)
  const navigate = useNavigate()

  const getVms = () => (userData?.VMs || []).map((vm) => ({
    ...vm,
    active: userData.activeVm === vm.VM,
  }))

  const vms = useMemo(getVms, [userData?.VMs, userData?.activeVm])

  const handleEvictUser = () => {
    askForConfirmationListener(
      'Are you sure you want to evict this user?\n\nThis action will permanently delete this user. This action can not be undone.',
      async () => {
        await datalabFacade.evictUser(userData.userName, user.pod)
        showSnackbarInProgress(`Evict User (${userData.userName}) in progress...`)
      }
    )
  }

  const handleDisableUser = () => {
    askForConfirmationListener(
      `Are you sure you want to disable this user?\n\n
      This action will disable the user's ability to sign in. 
      This user can be enabled again at a later date.`,
      async () => {
        await datalabFacade.disableUser(userData.userName, user.pod)
        showSnackbarInProgress(`Disable User (${userData.userName}) in progress...`)
      }
    )
  }

  const handleEnableUser = () => {
    askForConfirmationListener('Are you sure you want to enable this user?', async () => {
      await datalabFacade.enableUser(userData.userName, user.pod)
      showSnackbarInProgress(`Enable User (${userData.userName}) in progress...`)
    })
  }

  const handleUnassignAllProjectsClick = () => {
    const listOfProjects = vms.map((vm) => vm.project)
    askForConfirmationListener(
      'Are you sure you want to remove this user from all of their assigned Projects?',
      async () => {
        await datalabFacade.unassignAllProjects(userData.userName, listOfProjects, user.pod)
        showSnackbarInProgress(`Unassign User from Projects (${listOfProjects.length} 
        Projects -> ${userData.userName}) in progress...)`)
      }
    )
  }

  const handleEditBtnClick = () => {
    navigate('updateUser')
  }

  const handleRestoreBtnClick = () => {
    const restoredUser = {
      userName: userData.userName,
      initialPassword: generateInitialAzureAdPassword(),
      podId: user.pod,
    }

    askForConfirmationListener(
      `Are you sure you want to restore this user? 
      Please confirm if the existing email is correct before restoring,
      as this is where the user's login details will be sent.`,
      async () => {
        await datalabFacade.restoreUser(restoredUser)
        showSnackbarInProgress(`Restore user (${restoredUser.userName}) in progress...`)
        setOpenDialog(true)
        setDialogType('loginDetails')
      }
    )
  }

  const handleChangeActiveDsvmBtnClick = () => {
    navigate('changeActiveDsvm')
  }

  const handleResetPasswordBtnClick = () => {
    askForConfirmationListener(
      `The user '${userData.userName}' will be assigned a temporary password which must be changed on next sign in. Are you sure you want to reset this users password?`,
      async () => {
        const temporaryPassword = generateInitialAzureAdPassword()
        await datalabFacade.resetUserPassword(userData.userName, temporaryPassword)
        showSnackbarSuccess(`Reset password for (${userData.userName}) succeeded`)
        setTempPassword(temporaryPassword)
        setOpenDialog(true)
        setDialogType('resetPassword')
      }
    )
  }

  const handleResetMFABtnClick = () => {
    askForConfirmationListener(
      `The user '${userData.userName}' will be required to reregister an MFA method and password recovery email/phone. Are you sure you want to reset this user's authentication methods?`,
      async () => {
        await datalabFacade.resetUserMFA(userData.userName)
        showSnackbarSuccess(`Reset authentication methods for (${userData.userName}) succeeded`)
      }
    )
  }

  if (openDialog) {
    return (
      <TempPasswordDialog
        dialogType={dialogType}
        open={openDialog}
        tempPassword={tempPassword}
        callback={() => setOpenDialog(false)}
      />
    )
  }

  if (!userData) {
    return (
      <div className="action-button-container">
        <SkeletonActionButtons />
      </div>
    )
  }

  return (
    <>
      <div className="action-button-container">
        <RequiredRoleBoundary
          allowedRoles={[Role.USER_ADMIN]}
          functionProps={[
            'onClick',
          ]}
        >
          {(userData.status === 'ACTIVE' || userData.status === 'DISABLED')
                && (
                  <Button variant="outlined" color="primary" onClick={handleEvictUser}>
                    Evict&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-user-minus" />
                  </Button>
                )}
          {userData.status === 'ACTIVE'
                && (
                  <Button variant="outlined" color="primary" onClick={handleDisableUser}>
                    Disable&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-user-slash" />
                  </Button>
                )}
          {userData.status === 'DISABLED'
                && (
                  <Button variant="outlined" color="primary" onClick={handleEnableUser}>
                    Enable&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-user-check" />
                  </Button>
                )}
          {userData.status !== 'INACTIVE'
                && (
                  <Button variant="outlined" color="primary" onClick={handleResetPasswordBtnClick}>
                    Reset Password&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-key" />
                  </Button>
                )}
          {userData.status !== 'INACTIVE'
                && (
                  <Button variant="outlined" color="primary" onClick={handleResetMFABtnClick}>
                    Reset Auth Methods&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-user-lock" />
                  </Button>
                )}
        </RequiredRoleBoundary>
        <RequiredRoleBoundary
          allowedRoles={[Role.ADMIN]}
          functionProps={[
            'onClick',
          ]}
        >
          {userData.status === 'ACTIVE'
                && (
                  <Button
                    disabled={vms.length === 0}
                    variant="outlined"
                    color="primary"
                    onClick={handleChangeActiveDsvmBtnClick}
                  >
                    Change Active VM&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-laptop-medical" />
                  </Button>
                )}
          {userData.status === 'ACTIVE'
                && (
                  <Button
                    disabled={vms.length === 0}
                    variant="outlined"
                    color="primary"
                    onClick={handleUnassignAllProjectsClick}
                  >
                    Remove from all projects&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-user-times" />
                  </Button>
                )}
        </RequiredRoleBoundary>
        <RequiredRoleBoundary
          allowedRoles={[Role.USER_ADMIN]}
          functionProps={[
            'onClick',
          ]}
        >
          {(userData.status === 'ACTIVE' || userData.status === 'INACTIVE')
                && (
                  <Button variant="contained" color="primary" onClick={handleEditBtnClick}>
                    Edit&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-edit" />
                  </Button>
                )}
          {userData.status === 'INACTIVE'
                && (
                  <Button variant="contained" color="primary" onClick={handleRestoreBtnClick}>
                    Restore&nbsp;&nbsp;
                    {/* */}
                    <i className="fas fa-trash-restore" />
                  </Button>
                )}
        </RequiredRoleBoundary>
      </div>
      <Grid container direction="row" alignItems="stretch" className="details-grid">
        <Grid item xs={6}>
          <UnscrollablePaper variant="outlined">
            <SectionHeader>
              <Typography variant="h6" component="h3">
                Basic Attributes
              </Typography>
            </SectionHeader>
            <ComplexAttributeRow keyValuePairs={[
              { header: 'Username', value: userData.userName },
              { header: 'Name', value: userData.displayName },
              { header: 'Organisation', value: userData.organisation },
              { header: 'Email', value: userData.contactEmail },
              { header: 'Phone', value: userData.phone },
              { header: 'Date Registered', value: userData.registeredDate, date: true },
              { header: 'Status', value: userData.status, status: true },
            ]}
            />
          </UnscrollablePaper>
        </Grid>
        <Grid item xs={6}>
          <UnscrollablePaper variant="outlined">
            <SectionHeader>
              <Typography variant="h6" component="h3">
                Virtual Machines (
                {vms.length}
                )
              </Typography>
            </SectionHeader>
            <ArrayAttributeRow
              header="Virtual Machines"
              className="sub-action-container"
              columnHeaders={[
                { name: 'Project', key: 'project', linkTo: 'projects' },
                { name: 'VM Name', key: 'VM', linkTo: 'vms' },
                { name: 'Active', key: 'active', boolean: true },
              ]}
              keyValuePairs={vms}
              rowKey="project"
            />
          </UnscrollablePaper>

        </Grid>
        <Grid item xs={6}>
          <UnscrollablePaper variant="outlined">
            <SectionHeader>
              <Typography variant="h6" component="h3">
                Account Settings
              </Typography>
            </SectionHeader>
            <ComplexAttributeRow keyValuePairs={[
              { header: 'Email Opt Out', value: userData.emailOptOut, boolean: true },
            ]}
            />
          </UnscrollablePaper>
        </Grid>
        <Grid item xs={6} lg={6} xl={4}>
          <SectionScrollable variant="outlined">
            <SectionHeader>
              <Typography variant="h6" component="h3">
                User Tags
              </Typography>
            </SectionHeader>
            {userData && userData.tags
              ? userData.tags.map((tag) => (
                <Chip
                  key={`${tag.tagKey}-${tag.tagValue}`}
                  tabIndex={-1}
                  label={`${tag.tagKey}: ${tag.tagValue}`}
                />
              ))
              : []}
          </SectionScrollable>
        </Grid>
      </Grid>
    </>
  )
}

UserDetailsScreen.defaultProps = defaultProps
UserDetailsScreen.propTypes = propTypes
export default wrap((UserDetailsScreen))
