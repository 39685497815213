import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'
import AdminRoutes from './routes/AdminRoutes'
import AnalystRoutes from './routes/AnalystRoutes'
import LibraryAdminRoutes from './routes/LibraryAdminRoutes'
import BillingAdminRoutes from './routes/BillingAdminRoutes'
import PodOwnerRoutes from './routes/PodOwnerRoutes'
import PageNotFoundScreen from './screens/common/PageNotFoundScreen'
import { Role } from './enums/Role'
import DatalabFacade from './dataService/DatalabFacade'

const ROUTE_CONFIG = [
  {
    paths: AdminRoutes,
    roles: [Role.ADMIN, Role.USER_ADMIN, Role.DATA_ADMIN, Role.SYSADMIN, Role.READONLY],
  },
  {
    paths: AnalystRoutes,
    roles: [Role.ANALYST],
  },
  {
    paths: LibraryAdminRoutes,
    roles: [Role.LIBRARY_ADMIN, Role.SYSADMIN],
  },
  {
    paths: PodOwnerRoutes,
    roles: [Role.POD_OWNER, Role.SYSADMIN],
  },
  {
    paths: BillingAdminRoutes,
    roles: [Role.BILLING_ADMIN, Role.SYSADMIN],
  },
]

/**
 * Check if any from a list of roles appears in a list of available roles
 * @param {string[]} roles
 * @param {string[]} availableRoles
 * @return {bool} True if roles and availableRoles intersect
 */
const hasRole = (roles, availableRoles) => (
  roles.some((r) => availableRoles.includes(r))
)

const propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  commonProps: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    authButtonMethod: PropTypes.func.isRequired,
    datalabFacade: PropTypes.instanceOf(DatalabFacade),
  }).isRequired,
}

/**
 * DataLab app router component
 */
function Router(props) {
  const { roles: userRoles, commonProps } = props

  // Find all route groups that match current user role
  const configs = ROUTE_CONFIG.filter(({ roles }) => hasRole(userRoles, roles))
  // Combine all Route elements from matched groups into a single array
  const routes = configs.reduce((acc, { paths }) => ([
    ...acc,
    ...paths.map(({ path, Component }) => {
      // eslint-disable-next-line react/jsx-props-no-spreading
      const element = <Component routes={paths} {...commonProps} />
      return <Route exact path={path} element={element} key={path} />
    }),
  ]), [])

  return (
    <Routes>
      {routes}
      <Route path="*" element={<PageNotFoundScreen />} />
    </Routes>
  )
}

Router.propTypes = propTypes
export default Router
