import React from 'react'
import flow from 'lodash.flow'
import IconButton from '@mui/material/IconButton'
import BoltIcon from '@mui/icons-material/Bolt'
import UpdateIcon from '@mui/icons-material/Update'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import Grow from '@mui/material/Grow'
import { connect } from 'react-redux'
import ErrorIcon from '@mui/icons-material/Error'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import LensBlurIcon from '@mui/icons-material/LensBlur'
import SnoozeIcon from '@mui/icons-material/Snooze'
import BedtimeIcon from '@mui/icons-material/Bedtime'
import StopIcon from '@mui/icons-material/Stop'
import HelpIcon from '@mui/icons-material/Help'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Grid,
  Skeleton,
  Alert,
  Button,
  Typography,
  Tooltip,
  Paper,
  Box,
  Divider,
  Link,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { dateFutureDayOrTonight } from '../../util/StringUtils'
import TutorialDialog from '../../components/TutorialDialog'
import { useSnackbars } from '../../hooks/useSnackbars'
import { StoreAction } from '../../enums/StoreAction'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import Tutorial1Image from '../../../resources/images/avd_tut_diagram.min.svg'
import Tutorial2Image from '../../../resources/images/avd_login.min.svg'
import Tutorial3Image from '../../../resources/images/avd_rdc.min.svg'
import { useFeatures } from '../../hooks/useFeatures'
import {
  appUserShape, asyncOperationShape, vmShape,
} from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'
import HelpDialogListed from '../../components/HelpDialogListed'
import SplitButton from '../../components/SplitButton'
import VmConnectPreference from '../../enums/VmConnectPreference'

// Screen requires the following data providers and features injected
const wrap = flow([
  asBaseScreen,
])

const avdStepsLink = 'https://learn.microsoft.com/en-us/azure/virtual-desktop/users/connect-windows?pivots=remote-desktop-msi'
const DEFAULT_VM_CONNECT_PREFERENCE = 'windowsAppWeb'
const tutorialDialogTitle = 'Azure Virtual Desktop'
const TutorialPages = [
  <Box sx={{
    display: 'flex', flexDirection: 'column', alignItems: 'center', height: '600px', overflow: 'hidden',
  }}
  >
    <Typography variant="p" component="p" display="block">
      <p>
        Connect to your Virtual Machine (VM) using Azure Virtual Desktop (AVD).
      </p>
      <p style={{ marginTop: '5px' }}>
        The web version of AVD is selected by default and will launch in a new browser tab.
        However, you may choose to install the Remote Desktop Client (for Windows or macOS).
        Connecting via the web client is the simplest option,
        but the desktop client may support additional features and display options.
      </p>
    </Typography>
    <img src={Tutorial1Image} style={{ width: '1200px', height: '500px' }} alt="tutorial1" />
  </Box>,
  <Box sx={{
    display: 'flex', flexDirection: 'column', alignItems: 'center', height: '500px', overflow: 'hidden',
  }}
  >
    <Typography variant="h3" style={{ fontSize: '18px', alignSelf: 'flex-start', fontWeight: '500' }}>
      Connect with web client
    </Typography>
    <Typography variant="p" component="p" display="block">
      <p>
        Select &apos;Connect (web)&apos; to automatically launch the VM in a new browser window,
        where you will be asked to sign in.
        An intermediate settings menu will appear before you are then able to sign in.
      </p>
      <p style={{ marginTop: '5px' }}>
        The VM will start automatically.
      </p>
    </Typography>
    <img src={Tutorial2Image} style={{ width: '900px', height: '400px' }} alt="tutorial2" />
  </Box>,
  <Box sx={{
    display: 'flex', flexDirection: 'column', alignItems: 'center', height: '500px', overflow: 'hidden',
  }}
  >
    <Typography variant="h3" style={{ fontSize: '18px', alignSelf: 'flex-start', fontWeight: '500' }}>
      Connect with Remote Desktop Client (Windows/Mac)
    </Typography>
    <Typography variant="p" component="p" display="block">
      <p>
        The Remote Desktop Client has additional features such as multiple monitor support.
        Select &apos;Connect (Remote Desktop Client for Windows)&apos; to automatically launch the VM
        via the Remote Desktop Client for Windows (the client must be installed on your computer).
        Directly connecting from the My Projects page is not supported for the
        Remote Desktop Client for macOS.
        You will need to connect directly from the macOS client.
      </p>
      <p style={{ marginTop: '5px' }}>
        The VM will start automatically.
      </p>
      <p style={{ marginTop: '5px' }}>
        To download and install Remote Desktop Client, follow the&nbsp;
        <Link href={avdStepsLink} target="_blank">
          instructions here
        </Link>
        ,&nbsp;or contact your IT administrator.
      </p>
    </Typography>
    <img src={Tutorial3Image} style={{ width: '900px', height: '400px' }} alt="tutorial3" />
  </Box>,
]

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  operations: PropTypes.arrayOf(asyncOperationShape).isRequired,
  refreshVmState: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
}

/**
 * Displays a list of DataLab VMs
 * @param {object}   props                - The react props
 * @param {object}   props.user           - The logged in user
 * @param {object}   props.datalabFacade  - The datlabFacade object
 * @param {object[]} props.operations     - The redux store operations are injected via props
 * @param {boolean}  props.refreshVmState - Injected by the redux store, if true the data should be refetched
 *                                          as some external action has occurred which means the vm state may
 *                                          have changed
 * @param {object}   props.dispatch       - Injected by the redux store, used to dispatch new store actions
 * @returns {React.JSX.Element} a react component
 */
function VmsScreen({
  user, datalabFacade, operations, refreshVmState, dispatch,
}) {
  const [vms, setVms] = React.useState(undefined)
  const [activeVm, setActiveVm] = React.useState(undefined)
  const [nonActiveVms, setNonActiveVms] = React.useState(undefined)
  const [activatingVm, setActivatingVm] = React.useState(undefined)
  const [showingTutorial, setShowingTutorial] = React.useState(false)
  const [showingHelp, setShowingHelp] = React.useState(false)
  const [vmConnectPreference, setVmConnectPreference] = React.useState(undefined)

  const navigate = useNavigate()
  const { startLoading, stopLoading } = useFeatures()
  const { showSnackbarInProgress } = useSnackbars()

  const applyUserPreferences = async () => {
    const analystUser = await datalabFacade.getUser(user.username)
    if (!analystUser.hideTutorial) {
      setShowingTutorial(true)
    }
    setVmConnectPreference(analystUser.vmConnectPreference ?? DEFAULT_VM_CONNECT_PREFERENCE)
  }

  const load = async () => {
    const [databaseVms, projects] = await Promise.all([
      datalabFacade.list({
        collectionName: 'VMs',
        podId: user.pod,
        fields: ['VMname',
          'project',
          'type',
          'size',
          'version',
          'status',
          'powerState',
          'active',
          'buildDate',
          'destroyDate',
          'bypassShutdownUntil',
          'podId'],
        filters: [{
          property: 'status', condition: '$ne', value: 'DESTROYED', displayValue: 'DESTROYED',
        }],
      }),
      datalabFacade.list({
        collectionName: 'projects',
        podId: user.pod,
        fields: ['uuid',
          'projectName',
          'organisation',
          'users',
          'avdDesktopObjectId',
          'avdWorkspaceId',
          'projectUsageDatalake',
          'projectStorageSizeDatalake'],
        filters: [{
          property: 'status', condition: '$eq', value: 'OPEN', displayValue: 'OPEN',
        }],
      }),
    ])

    const vmsExtended = databaseVms.data.map((vm) => ({
      vm,
      project: projects.data.find((p) => p.uuid === vm.project),
      daysUntilNextRebuild: Math.round(
        (
          new Date((vm.destroyDate ?? vm.buildDate) ?? new Date()).getTime()
          + (1000 * 3600 * 24 * 30) - new Date().getTime()
        )
        / (1000 * 3600 * 24)
      ),
    })).sort((a, b) => {
      if (a.status === 'READY') {
        return -1
      }
      if (b.status === 'READY') {
        return 1
      }
      return 0
    })

    setVms(vmsExtended)
    setActiveVm(vmsExtended.find((vm) => vm.vm.active))
    setNonActiveVms(vmsExtended.filter((vm) => !vm.vm.active))
  }

  React.useEffect(() => {
    applyUserPreferences()
    load()
  }, [])

  React.useEffect(() => {
    // Check the store for any in progress active virtual machine operations so we can surface this
    // in the vm cards
    const activatingVmInOperation = operations?.find((o) => o.endpoint === '/activateVm')?.requestBody.vmName
    if (activatingVm !== activatingVmInOperation) {
      setActivatingVm(activatingVmInOperation)
    }
  }, [operations])

  React.useEffect(() => {
    // This is true when a NOTIFY_VM_STATE_CHANGED action is invoked on the redux store. Meaning a
    // process (most likely activeVm async operation) is indicating a process has completed and the
    // state of the vms may have changed, we should reload the vm data
    if (refreshVmState) {
      dispatch({ type: StoreAction.ACKNOwLEDGE_VM_STATE_CHANGED })
      load()
    }
  }, [refreshVmState])

  const handleRowSelected = (vm) => {
    navigate(`/vms/${vm.vm.VMname}`)
  }

  const handleConfirmedTutorial = (dontShowAgainChecked) => {
    setShowingTutorial(false)
    if (dontShowAgainChecked) {
      datalabFacade.updateUserSelfService({ userName: user.username, hideTutorial: true })
    }
  }

  const handleConfirmedHelp = () => {
    setShowingHelp(false)
  }

  const handleActivateVm = async (vm) => {
    startLoading()
    setActivatingVm(vm.vm.VMname)
    await datalabFacade.activateVm(vm.vm.VMname, user.pod)

    showSnackbarInProgress(`Activating ${vm.project.uuid.toUpperCase()} please wait...`)
    stopLoading()
  }

  const projectUsage = activeVm?.project?.projectUsageDatalake
  const projectLimit = activeVm?.project?.projectStorageSizeDatalake
  const datalakeUsageExceedsCapacity = projectUsage > projectLimit
  const datalakeUsageExceedsWarningThreshold = projectUsage >= projectLimit * 0.9

  return (
    <>
      <TutorialDialog
        open={showingTutorial}
        closeCallback={handleConfirmedTutorial}
        title={tutorialDialogTitle}
        dontShowAgainCheckbox
        pages={TutorialPages}
      />
      <TutorialDialog
        open={showingHelp}
        closeCallback={handleConfirmedHelp}
        dontShowAgainCheckbox={false}
        title={tutorialDialogTitle}
        pages={TutorialPages}
      />

      <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Box sx={{
          ml: 5, height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        }}
        >
          <Typography
            variant="overline"
            sx={{ fontWeight: 'bold' }}
          >
            Active Project Machine
          </Typography>
          <IconButton
            color="primary"
            onClick={() => setShowingHelp(true)}
            aria-label="virtual machine help"
            size="large"
          >
            <HelpIcon />

          </IconButton>
        </Box>
        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)', marginBottom: '20px' }} />

        {datalakeUsageExceedsWarningThreshold && !datalakeUsageExceedsCapacity && (
          <Alert sx={{ m: 2, width: '100%' }} severity="warning">
            This project&apos;s Data Lake container capacity is nearing it&apos;s quota
            . Free up space or increase your quota by contacting your administrator.
          </Alert>
        )}
        {datalakeUsageExceedsCapacity && (
          <Alert sx={{ m: 2, width: '100%' }} severity="error">
            This project&apos;s Data Lake container capacity has exceeded the quota
            . Please free up space or increase the quota by contacting your administrator.
          </Alert>
        )}
        <Grid
          container
          sx={{ margin: '20px', paddingLeft: '40px' }}
          rowSpacing={{ xs: 1, sm: 2, md: 3 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {!vms && (
            <Grid item>
              <Skeleton variant="rectangular" width={400} height={300} />
              {' '}
            </Grid>
          )}
          {vms && activeVm
            && (
              <VirtualMachineCard
                isActive
                vm={activeVm}
                handleSelected={handleRowSelected}
                activatingVm={activatingVm}
                datalabFacade={datalabFacade}
                user={user}
                vmConnectPreference={vmConnectPreference}
              />
            )}
          {' '}
          {vms && !activeVm && (
            <Alert sx={{ m: 2, width: '100%' }} severity="info">
              You don&apos;t have an active project machine at the moment
            </Alert>
          )}
        </Grid>
        <Box sx={{
          ml: 5, height: '48px', display: 'flex', alignItems: 'center',
        }}
        >
          <Typography variant="overline">Other Project Machines (Currently locked)</Typography>
        </Box>

        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)', marginBottom: '20px' }} />
        <Grid
          container
          sx={{ paddingLeft: '40px', paddingBottom: '40px' }}
          rowSpacing={{ xs: 1, sm: 2, md: 3 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {!nonActiveVms
            && (
              <>
                <Grid item>
                  {' '}
                  <Skeleton variant="rectangular" width={400} height={300} />
                  {' '}
                </Grid>
                <Grid item>
                  {' '}
                  <Skeleton variant="rectangular" width={400} height={300} />
                  {' '}
                </Grid>
                <Grid item>
                  {' '}
                  <Skeleton variant="rectangular" width={400} height={300} />
                  {' '}
                </Grid>
              </>
            )}
          {nonActiveVms && nonActiveVms.length > 0
            && nonActiveVms.map(
              (vm) => (
                <VirtualMachineCard
                  key={vm.vm.VMname}
                  vm={vm}
                  handleSelected={handleRowSelected}
                  handleActivateVm={handleActivateVm}
                  activatingVm={activatingVm}
                  datalabFacade={datalabFacade}
                  user={user}
                />
              )
            )}
          {nonActiveVms && nonActiveVms.length === 0 && (
            <Alert sx={{ m: 2, width: '100%' }} severity="info">
              You are not assigned to any other projects
            </Alert>
          )}
        </Grid>

      </div>
    </>
  )
}

const virtualMachineCardPropTypes = {
  isActive: PropTypes.bool,
  vm: PropTypes.shape({
    vm: vmShape.isRequired,
    project: PropTypes.shape({
      projectName: PropTypes.string.isRequired,
      organisation: PropTypes.string.isRequired,
      users: PropTypes.arrayOf(PropTypes.string).isRequired,
      uuid: PropTypes.string.isRequired,
      avdWorkspaceId: PropTypes.string.isRequired,
      avdDesktopObjectId: PropTypes.string.isRequired,
    }).isRequired,
    daysUntilNextRebuild: PropTypes.number.isRequired,
  }).isRequired,
  handleActivateVm: PropTypes.func,
  handleSelected: PropTypes.func.isRequired,
  activatingVm: PropTypes.string,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  user: appUserShape.isRequired,
  vmConnectPreference: PropTypes.string,
}

const virtualMachineCardDefaultPropTypes = {
  isActive: false,
  activatingVm: null,
  handleActivateVm: null,
  vmConnectPreference: null,
}

/**
 * Displays a list of DataLab VMs
 * @param {object}        props                     - The react props
 * @param {boolean}       props.isActive            - Whether the vm is the current active vm for
 *                                                    the user
 * @param {object}        props.vm                  - The VM object
 * @param {Function}      props.handleActivateVm    - Callback for when activate is clicked on the
 *                                                    vm tile
 * @param {Function}      props.handleSelected      - Callback for when the manage button is
 *                                                    selected on the vm tile
 * @param {string | null} props.activatingVm        - The name of the vm that is currently
 *                                                    activating, or null
 * @param {object}        props.datalabFacade       - The datlabFacade object
 * @param {object}        props.user                - The logged in user
 * @param {string | null} props.vmConnectPreference - A string that corresponds to one of the
 *                                                    {@link VmConnectPreference} values or null
 * @returns {React.JSX.Element} a react component
 */
function VirtualMachineCard({
  isActive,
  vm,
  handleActivateVm,
  handleSelected,
  activatingVm,
  datalabFacade,
  user,
  vmConnectPreference,
}) {
  const [isPowerRunningHovered, setIsPowerRunningHovered] = React.useState(false)
  const [isPowerStoppedHovered, setIsPowerStoppedHovered] = React.useState(false)
  const [powerState, setPowerState] = React.useState(vm.vm.powerState)
  const [showingAvdHelp, setShowingAvdHelp] = React.useState(false)

  const { setConfirm } = useFeatures()

  let rebuildColor = 'grey'
  if (vm.daysUntilNextRebuild < 5) rebuildColor = '#c61400'
  if (vm.daysUntilNextRebuild < 10) rebuildColor = '#ff9933'

  React.useEffect(() => {
    setPowerState(vm.vm.powerState)
  }, [vm])

  const { showSnackbarInProgress } = useSnackbars()

  const handleMouseEnterPowerRunning = () => setIsPowerRunningHovered(true)
  const handleMouseLeavePowerRunning = () => setIsPowerRunningHovered(false)
  const handleMouseEnterPowerStopped = () => setIsPowerStoppedHovered(true)
  const handleMouseLeavePowerStopped = () => setIsPowerStoppedHovered(false)

  const stopVm = () => {
    setConfirm({
      message: 'Are you sure you want to stop this VM?',
      callback: async () => {
        await datalabFacade.stopVM(vm.vm.VMname, vm.vm.podId)
        setPowerState('DEALLOCATING')
        showSnackbarInProgress(`Stop Virtual Machine (${vm.vm.VMname}) in progress...`)
      },
    })
  }

  const startVm = () => {
    setConfirm({
      message: 'Are you sure you want to start this VM?',
      callback: async () => {
        await datalabFacade.startVM(vm.vm.VMname, vm.vm.podId)
        setPowerState('STARTING')
        showSnackbarInProgress(`Start Virtual Machine (${vm.vm.VMname}) in progress...`)
      },
    })
  }

  return (
    <>
      {showingAvdHelp
      && (
      <HelpDialogListed
        open={showingAvdHelp}
        callback={() => setShowingAvdHelp(false)}
        title="Connecting to Azure Virtual Desktop"
        headers={[
          { formatted: 'Client', key: 'client', linkKey: 'link' },
          { formatted: '', key: 'moreInfo', linkKey: 'moreInfoLink' },
        ]}
        rows={[
          {
            client: 'Windows App web client',
            link: 'https://windows.cloud.microsoft',
            moreInfo: 'More info',
            moreInfoLink: 'https://learn.microsoft.com/en-us/windows-app/overview',
          },
          {
            client: 'AVD web client',
            link: 'https://client.wvd.microsoft.com/arm/webclient/',
            moreInfo: 'More info',
            moreInfoLink: 'https://learn.microsoft.com/en-us/azure/virtual-desktop/users/connect-web',
          },
          {
            client: 'Remote Desktop client for Windows',
            moreInfo: 'More info',
            moreInfoLink: 'https://learn.microsoft.com/en-us/azure/virtual-desktop/users/connect-windows',
          },
          {
            client: 'Remote Desktop client for macOS',
            moreInfo: 'More info',
            moreInfoLink: 'https://learn.microsoft.com/en-us/azure/virtual-desktop/users/connect-macos',
          }]}
        rowKey="type"
        message='The following Azure Virtual Desktop clients are supported for connecting to DataLab/SEAD virtual machines. If you connect using the client directly (rather than using the provided "connect" button which automatically launches the correct VM in the Windows App web client) you should only connect to your active project machine otherwise you will receive an error.'
      />
      )}

      <Grid item>
        <Grow in>
          <Paper
            elevation={(isActive || activatingVm === vm.vm.VMname) ? 18 : 4}
            sx={{
              width: 400, height: 300, borderRadius: '8px', display: 'flex', flexDirection: 'column',
            }}
          >
            { /* Project ID and manage button */}
            <Box sx={{
              height: 42,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '15px 15px 5px 20px',
            }}
            >
              <Typography
                variant="h4"
                sx={{ opacity: vm.vm.status === 'READY' ? '.9' : '.6' }}
              >
                {vm.project.uuid}
              </Typography>
              <Button color="primary" size="small" onClick={() => handleSelected(vm)}>Manage</Button>
            </Box>
            { /* Project name */}
            <Typography
              sx={{
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                display: '-webkit-box',
                textOverflow: 'ellipsis',
                wordWrap: 'break-word',
                overflow: 'hidden',
                height: '2em',
                lineHeight: '1em',
                margin: '15px 15px 0px 20px',
              }}
              variant="subtitle"
            >
              {vm.project.projectName}
            </Typography>

            { /* Action button */}
            <Box sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            >
              {activatingVm === vm.vm.VMname
              && (
                <div style={{
                  width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column',
                }}
                >
                  <CircularProgress size={30} sx={{ margin: '7px' }} />
                  <Typography variant="caption">Activating...</Typography>
                </div>
              )}
              {vm.vm.status === 'READY' && isActive && !activatingVm
              && vm.vm.version !== '2024'
              && (
                <Box sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                >
                  {vmConnectPreference
                    ? (
                      <SplitButton
                        initialSelected={vmConnectPreference}
                        onChangeSelected={
                      (buttonAction) => datalabFacade.updateUserSelfService(
                        { userName: user.username, vmConnectPreference: buttonAction.key }
                      )
                    }
                        buttonActions={[
                          {
                            key: VmConnectPreference.WINDOWS_APP_WEB,
                            text: 'Connect (web client)',
                            href:
                        `https://windows365.microsoft.com/webclient/avd/${vm.project.avdWorkspaceId}/${vm.project.avdDesktopObjectId}?useOldApp=true`,
                          },
                          {
                            key: VmConnectPreference.REMOTE_DESKTOP_CLIENT_WINDOWS,
                            text: 'Connect (Remote Desktop Client for Windows)',
                            href:
                        `ms-avd:connect?workspaceId=${vm.project.avdWorkspaceId}&resourceid=${vm.project.avdDesktopObjectId}&username=${user.username}&version=0`,
                          },
                          {
                            text: 'Other ways to connect...',
                            overrideSelected: () => setShowingAvdHelp(true),
                          },
                        ]}
                        disabled={activatingVm}
                      />
                    )
                    : <CircularProgress size={30} sx={{ margin: '7px' }} />}
                </Box>
              )}
              {vm.vm.status === 'READY' && isActive && activatingVm && activatingVm !== vm.vm.VMname
              && (
                <div style={{
                  width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column',
                }}
                >

                  <CircularProgress size={30} sx={{ margin: '7px' }} />
                  <Typography variant="caption">Deactivating...</Typography>
                </div>
              )}
              {vm.vm.status === 'READY' && !isActive && activatingVm !== vm.vm.VMname
              && (
                <Button
                  disabled={activatingVm}
                  variant="outlined"
                  sx={{
                    borderColor: 'green',
                    color: 'green',
                    width: 200,
                    fontSize: '1.1em',
                    '&:hover': {
                      color: '#00b200',
                      borderColor: '#00b200',
                    },
                  }}
                  onClick={() => handleActivateVm(vm)}
                >
                  Activate
                  <ArrowUpwardIcon sx={{ marginLeft: '10px' }} />
                </Button>
              )}
              {vm.vm.status === 'BUILDING' && (
              <Button
                sx={{ textTransform: 'none' }}
                variant="text"
                color="primary"
                onClick={() => handleSelected(vm)}
              >
                Building, please wait...
              </Button>
              )}
            </Box>

            { /* Status row */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ml: '8px',
              height: 30,
              mt: 'auto',
            }}
            >
              <Box />
              {vm.vm.status === 'READY' && powerState === 'RUNNING' && (
              <Tooltip title="Automatic shutdown time">
                <Box sx={{ color: 'grey', ml: '10px', mr: '10px' }} aria-label="auto shutdown">
                  <Typography
                    sx={{ mr: '5px', fontWeight: 'bold' }}
                    variant="caption"
                  >
                    {dateFutureDayOrTonight(vm.vm.bypassShutdownUntil
                      ? new Date(vm.vm.bypassShutdownUntil) : undefined)}
                  </Typography>
                  <SnoozeIcon htmlColor="green" />
                </Box>
              </Tooltip>
              )}
            </Box>

            { /* Power status row */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
              ml: '5px',
              height: 30,
            }}
            >
              {vm.vm.status === 'READY' && powerState === 'RUNNING' && (
              <IconButton
                sx={{ justifyContent: 'flex-start', width: '120px' }}
                aria-label="power state"
                onMouseEnter={handleMouseEnterPowerRunning}
                onMouseLeave={handleMouseLeavePowerRunning}
                onClick={stopVm}
              >
                {isPowerRunningHovered ? (
                  <StopIcon htmlColor="green" />
                ) : (
                  <BoltIcon htmlColor="green" />
                )}
                <Typography variant="caption" sx={{ color: 'green', ml: '6px', fontWeight: 'bold' }}>
                  {' '}
                  {isPowerRunningHovered ? 'STOP' : 'RUNNING'}
                </Typography>
              </IconButton>
              )}
              {vm.vm.status === 'READY' && powerState === 'DEALLOCATED' && (
              <IconButton
                sx={{ justifyContent: 'flex-start', width: '120px' }}
                aria-label="power state"
                onMouseEnter={handleMouseEnterPowerStopped}
                onMouseLeave={handleMouseLeavePowerStopped}
                onClick={startVm}
              >
                {isPowerStoppedHovered ? (
                  <PlayArrowIcon htmlColor="green" />
                ) : (
                  <PowerSettingsNewIcon htmlColor="gray" />
                )}
                <Typography
                  variant="caption"
                  sx={{
                    color: isPowerStoppedHovered ? 'green' : 'gray',
                    marginLeft: '6px',
                    fontWeight: 'bold',
                  }}
                >
                  {isPowerStoppedHovered ? 'START' : 'OFF'}
                </Typography>
              </IconButton>
              )}
              {!['RUNNING', 'DEALLOCATED'].includes(powerState) && vm.vm.status === 'READY' && (
              <IconButton sx={{ width: 110, justifyContent: 'flex-start' }} aria-label="power state">
                <LensBlurIcon htmlColor="gray" />
                <Typography variant="caption" sx={{ color: 'gray', marginLeft: '6px', fontWeight: 'bold' }}>
                  {powerState}
                  ...
                </Typography>
              </IconButton>
              )}

              { /* Auto rebuild time or error/dormant */}
              {vm.vm.status === 'READY' && (
              <Tooltip title="Automatic rebuild time">
                <Box sx={{ color: 'grey', ml: '10px', mr: '10px' }} aria-label="auto shutdown">
                  <Typography
                    sx={{
                      mr: '5px',
                      fontWeight: 'bold',
                      color: rebuildColor,
                    }}
                    variant="caption"
                  >
                    {`${vm.daysUntilNextRebuild} days`}
                  </Typography>
                  <UpdateIcon color={rebuildColor} />
                </Box>
              </Tooltip>
              )}
              {vm.vm.status === 'ERROR' && (
              <Box sx={{ ml: '10px', mr: '10px' }} aria-label="auto shutdown">
                <ErrorIcon htmlColor="red" />
                <Typography
                  sx={{
                    ml: '5px', fontWeight: 'bold', color: 'red', opacity: '0.7',
                  }}
                  variant="caption"
                >
                  ERROR
                </Typography>
              </Box>
              )}
              {vm.vm.status === 'DORMANT' && (
              <Box sx={{ ml: '10px', mr: '10px' }} aria-label="auto shutdown">
                <BedtimeIcon htmlColor="grey" />
                <Typography
                  sx={{ ml: '5px', fontWeight: 'bold', opacity: '0.7' }}
                  variant="caption"
                >
                  DORMANT
                </Typography>
              </Box>
              )}
            </Box>

            { /* Type/size/version */}
            <Box sx={{
              backgroundColor: powerState === 'RUNNING' && vm.vm.status === 'READY' ? 'green' : 'dimgray', borderRadius: '0 0 8px 8px', opacity: '0.75', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'flex-start',
            }}
            >
              <table style={{ fontSize: '.7em', color: 'white', width: '100%' }}>
                <tbody>
                  <tr>
                    <th style={{ width: '33%', textAlign: 'center' }}>
                      SIZE |
                      <Box component="span" fontWeight="bold">
                        {' '}
                        {vm.vm.size.toUpperCase()}
                      </Box>
                    </th>
                    <th style={{ width: '33%', textAlign: 'center' }}>
                      TYPE |
                      <Box component="span" fontWeight="bold">
                        {' '}
                        {vm.vm.type.toUpperCase()}
                      </Box>
                    </th>
                    <th style={{ width: '33%', textAlign: 'center' }}>
                      VERSION |
                      <Box component="span" fontWeight="bold">
                        {' '}
                        {vm.vm.version.toUpperCase()}
                      </Box>
                    </th>
                  </tr>
                </tbody>
              </table>
            </Box>

          </Paper>
        </Grow>
      </Grid>
    </>
  )
}

VirtualMachineCard.propTypes = virtualMachineCardPropTypes
VirtualMachineCard.defaultProps = virtualMachineCardDefaultPropTypes

/**
 * Callback passed to react-redux connect function which maps the store state to props that are
 * injected into the component
 * @param {object} state - The store state
 * @returns {object} an object containing key/value pair props that will be injected into the component
 */
function mapStateToProps(state) {
  return {
    refreshVmState: state.refreshVmState,
    operations: state.operations,
  }
}

VmsScreen.propTypes = propTypes

export default wrap((connect(mapStateToProps)(VmsScreen)))

