import React from 'react'
import { createRoot } from 'react-dom/client'
import Datalab from './js/Datalab'
import 'typeface-roboto'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Datalab />
)
