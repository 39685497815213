import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Tooltip,
  Chip,
  TextField,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { ExportToCsv } from 'export-to-csv'
import flatten from 'flat'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import { useSearchParams } from 'react-router-dom'
import Cancel from '@mui/icons-material/Clear'
import Search from '@mui/icons-material/Search'
import { format, utcToZonedTime } from 'date-fns-tz'
import qs from 'qs'
import PropTypes from 'prop-types'
import { FilterConditionLabel } from '../enums/FilterConditionLabel'
import DatalabFacade from '../dataService/DatalabFacade'
import {
  tagShape,
} from '../propTypeShapes'
import SpinnerIndeterminateModal from './SpinnerIndeterminateModal'
import Row from './paginatedDataTable/Row'
import TableHead from './paginatedDataTable/TableHead'
import TableFilterDialog from './paginatedDataTable/TableFilterDialog'
import {
  tableHeadersProps, filterProps, orderProps, customActionProps,
} from './paginatedDataTable/propTypes'

const propTypes = {
  defaultSort: orderProps.isRequired,
  headers: tableHeadersProps.isRequired,
  defaultFilter: filterProps,
  rowKey: PropTypes.string.isRequired,
  statusKey: PropTypes.string,
  limitCount: PropTypes.number,
  podId: PropTypes.string,
  timeRangeKey: PropTypes.string,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  collectionName: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagShape),
  queryFilters: PropTypes.arrayOf(filterProps),
  splitDateTimes: PropTypes.arrayOf(PropTypes.string.isRequired),
  tableCaption: PropTypes.string.isRequired,
  csvExportFileName: PropTypes.string.isRequired,
  nightwatch: PropTypes.string,
  deletable: PropTypes.func,
  updatable: PropTypes.func,
  downloadable: PropTypes.func,
  customActions: PropTypes.arrayOf(customActionProps),
  onRowChangeCallback: PropTypes.func,
  onChecked: PropTypes.func,
  checkboxSelectable: PropTypes.bool,
  disableArrayLinks: PropTypes.bool,
}
const defaultProps = {
  statusKey: null,
  tags: [],
  queryFilters: [],
  splitDateTimes: [],
  nightwatch: 'unset',
  deletable: null,
  updatable: null,
  downloadable: null,
  customActions: [],
  disableArrayLinks: null,
  limitCount: undefined,
  timeRangeKey: undefined,
  checkboxSelectable: false,
  defaultFilter: null,
  podId: null,
  onRowChangeCallback: null,
  onChecked: () => {},
}

const StyledPagination = styled(Pagination)({
  '& ul': {
    justifyContent: 'center',
  },
})

const australianEasternTimeZone = 'Australia/Sydney'

function PaginatedDataTable({
  defaultSort,
  headers,
  defaultFilter,
  rowKey,
  statusKey,
  limitCount,
  podId,
  timeRangeKey,
  datalabFacade,
  collectionName,
  tags,
  queryFilters = [],
  splitDateTimes,
  tableCaption,
  csvExportFileName,
  nightwatch,
  deletable,
  updatable,
  downloadable,
  customActions,
  onRowChangeCallback,
  onChecked,
  checkboxSelectable,
  disableArrayLinks,
}) {
  const timeRange = [
    'Last Week',
    'Last Month',
    'Last Three Months',
    'Last Six Months',
    'Last Year',
    'All Time',
  ]
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [order, setOrder] = React.useState(defaultSort)
  const [selected, setSelected] = React.useState(null)
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [tableData, setTableData] = React.useState([])
  const [checked, setChecked] = React.useState([])
  const [totalDataCount, setTotalDataCount] = React.useState(0)
  const [queryTime, setQueryTime] = React.useState(0)
  const [dataIndexStart, setDataIndexStart] = React.useState(0)
  const [dataIndexEnd, setDataIndexEnd] = React.useState(0)
  const [tableHeaders] = React.useState(headers)
  const [filtering, setFiltering] = React.useState(false)
  const [filters, setFilters] = React.useState(defaultFilter ? [defaultFilter] : [])
  const [loading, setLoading] = React.useState(true)
  const [forceRefresh, setForceRefresh] = React.useState(false)
  const [refreshing, setRefreshing] = React.useState(true)
  const [exporting, setExporting] = React.useState(false)
  const [quickSearchText, setQuickSearchText] = React.useState('')
  const [shouldCount, setShouldCount] = React.useState(true)
  const [selectedTimeRange, setSelectedTimeRange] = React.useState(timeRangeKey ? timeRange[0] : undefined)
  const [submittedQuickSearchText, setSubmittedQuickSearchText] = React.useState(undefined)
  const quickSearchRef = React.createRef()
  const [searchParams, setSearchParams] = useSearchParams()
  const [initialFilter, setInitialFilter] = React.useState(true)

  React.useEffect(() => {
    if (!firstLoad) {
      setRefreshing(true)
      const query = {
        search: {
          filter: filters,
          sort: order,
          text: submittedQuickSearchText,
          timeRange: selectedTimeRange,
          timeRangeKey,
        },
      }

      setSearchParams(qs.stringify(query, { allowDots: true }), { replace: initialFilter, state: undefined })
      setInitialFilter(false)

      const allFilters = [...filters, ...queryFilters]

      datalabFacade.list({
        collectionName,
        podId,
        fields: tableHeaders.map((th) => th.id),
        page,
        pageSize: rowsPerPage,
        filters: allFilters,
        sort: order,
        selectedTimeRange,
        timeRangeKey,
        quickSearchQuery: submittedQuickSearchText,
        limitCount,
        shouldCount,
      }).then((data) => {
        setTableData(data.data)
        if (shouldCount) {
          setTotalDataCount(data.totalCount)
        }
        setQueryTime(data.totalTime)
        setDataIndexStart((page - 1) * rowsPerPage)
        setDataIndexEnd(((page - 1) * rowsPerPage) + data.data.length)
        setLoading(false)
        setRefreshing(false)
      }).catch((err) => setTableData(() => {
        throw err
      }))
    }
  }, [
    timeRangeKey,
    firstLoad,
    collectionName,
    podId,
    datalabFacade,
    page,
    filters,
    submittedQuickSearchText,
    order,
    rowsPerPage,
    tableHeaders,
    forceRefresh,
    selectedTimeRange,
    limitCount,
    shouldCount,
  ])

  React.useEffect(() => {
    if (firstLoad) {
      // Load in url params (for bookmarked queries)
      const searchParam = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true, allowDots: true })
      if (searchParam.search) {
        const query = searchParam.search
        if (query.filter) {
          setFilters(query.filter)
        } else {
          setFilters([])
        }
        if (query.sort) {
          setOrder(query.sort)
        } else {
          setOrder([])
        }
        if (query.text) {
          setSubmittedQuickSearchText(query.text)
          setQuickSearchText(query.text)
        } else {
          setSubmittedQuickSearchText(undefined)
          setQuickSearchText('')
        }
        if (query.timeRange) {
          setSelectedTimeRange(query.timeRange)
        } else {
          setSelectedTimeRange(timeRangeKey ? timeRange[0] : undefined)
        }
      }
      setFirstLoad(false)
    }
  }, [
    timeRangeKey,
    firstLoad,
    collectionName,
    podId,
    datalabFacade,
    page,
    filters,
    submittedQuickSearchText,
    order,
    rowsPerPage,
    tableHeaders,
    forceRefresh,
    selectedTimeRange,
    limitCount,
    shouldCount,
  ])

  const handleExportToCsv = async () => {
    setExporting(true)

    const allFilters = [...filters, ...queryFilters]

    let columns = tableHeaders.map((th) => th.id)

    const dateColumns = tableHeaders
      .filter((th) => ['date-time', 'date-time-verbose', 'date-time-verbose-or-live'].includes(th.type))
      .map((th) => th.id)
    const data = await datalabFacade.list({
      collectionName,
      podId,
      fields: columns,
      filters: allFilters,
      sort: order,
      selectedTimeRange,
      timeRangeKey,
      quickSearchQuery: submittedQuickSearchText,
    })

    // We want tags to be exported as individual columns
    // Fetch the system tag keys to set as additional columns
    let tagsToExport = []
    if (columns.includes('tags')) {
      tagsToExport = await datalabFacade.getTags(podId)
      columns = columns.concat(tagsToExport.map((t) => `Tag: ${t.tagKey}`)).filter((c) => c !== 'tags')
    }

    if (splitDateTimes) {
      columns = columns.filter((column) => !splitDateTimes.includes(column))
      splitDateTimes.forEach((e) => {
        columns.push(`${e}_date`)
        columns.push(`${e}_time`)
      })
    }

    const formattedData = data.data.map((row) => {
      const formattedRow = Object.assign(...columns.map((key) => ({ [key]: row[key] })))
      Object.keys(row).forEach((key) => {
        if (Array.isArray(row[key])) {
          formattedRow[key] = row[key].join()
        }
        if (splitDateTimes && splitDateTimes.includes(key) && row[key]) {
          formattedRow[`${key}_date`] = format(
            utcToZonedTime(new Date(row[key]), australianEasternTimeZone),
            'yyyy/MM/dd',
            { timeZone: 'Australia/Sydney' }
          )
          formattedRow[`${key}_time`] = format(
            utcToZonedTime(new Date(row[key]), australianEasternTimeZone),
            'HH:mm',
            { timeZone: 'Australia/Sydney' }
          )
        } else if (dateColumns.includes(key) && row[key]) {
          formattedRow[key] = format(
            utcToZonedTime(new Date(row[key]), australianEasternTimeZone),
            'yyyy/MM/dd HH:mm',
            { timeZone: 'Australia/Sydney' }
          )
        }
      })

      // remove any null or undefined values
      columns.forEach((key) => {
        if (!formattedRow[key]) {
          formattedRow[key] = ''
        }
      })

      // Map the tag values to the tag key columns There is a chance that there are multiple tag
      // values for a tag key, in this case just join the values as a string (we are not going to do
      // anything more complicated than that)
      tagsToExport.forEach((t) => {
        const rowTagValues = row.tags
          ? row.tags.filter((rt) => rt.tagKey === t.tagKey).map((rt) => rt.tagValue)
          : []
        formattedRow[`Tag: ${t.tagKey}`] = rowTagValues.join()
      })
      // Can now delete the tags entry for the row
      delete formattedRow.tags

      return flatten(formattedRow)
    })

    const csvExportOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `${tableCaption} TZ(${format(new Date(), 'zzz zzzz', { timeZone: 'Australia/Sydney' })})`,
      filename: csvExportFileName,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns,
    }

    const csvExporter = new ExportToCsv(csvExportOptions)

    csvExporter.generateCsv(formattedData)
    setExporting(false)
  }

  const handleRequestSort = (property) => {
    // dont sort array or tag types
    if (property.type === 'array' || property.type === 'tag-array' || property.type === 'tag-value-array') {
      return
    }
    const existingSort = order ? order.property === property.id : false

    if (existingSort && order.direction === 'asc') {
      setOrder({ property: property.id, direction: 'desc' })
    } else if (existingSort && order.direction === 'desc') {
      setOrder(undefined)
    } else {
      setOrder({ property: property.id, direction: 'asc' })
    }
    setShouldCount(false)
    setPage(1)
  }

  const handleSelectRow = (event, id) => {
    setSelected(id)
  }

  const handleChangePage = (event, value) => {
    setShouldCount(false)
    setPage(value)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value)
    setPage(1)
  }

  const handleAddFilter = (filter) => {
    const updatedFilters = filters.concat(filter)
    setFilters(updatedFilters)
    setShouldCount(true)
    setPage(1)
  }

  const handleDeleteFilter = (index) => {
    const updatedFilters = [...filters]
    updatedFilters.splice(index, 1)
    setFilters(updatedFilters)
    setShouldCount(true)
    setPage(1)
  }

  const handleClearFilters = () => {
    setFilters([])
    setQuickSearchText('')
    setSubmittedQuickSearchText(undefined)
    setShouldCount(true)
    setPage(1)
  }

  const handleFilterIdLink = (id) => {
    setFilters([{
      property: '_id', condition: '$eq', value: id, displayValue: id,
    }])
    setQuickSearchText('')
    setSubmittedQuickSearchText(undefined)
    setShouldCount(true)
    setPage(1)
  }

  const handleFilterParentIdLink = (id) => {
    setFilters([{
      property: 'parentId', condition: '$eq', value: id, displayValue: id,
    }])
    setQuickSearchText('')
    setSubmittedQuickSearchText(undefined)
    setShouldCount(true)
    setPage(1)
  }

  const handleSubmitQuickSearch = () => {
    setSubmittedQuickSearchText(quickSearchText)
    quickSearchRef.current.focus()
    setShouldCount(true)
    setPage(1)
  }

  const handleCancelQuickSearch = () => {
    setQuickSearchText('')
    setSubmittedQuickSearchText(undefined)
    quickSearchRef.current.focus()
    setShouldCount(true)
    setPage(1)
  }

  const handleRefresh = () => {
    setForceRefresh(!forceRefresh)
    setShouldCount(true)
    setChecked([])
  }

  const handleSetTimeRange = (e) => {
    setSelectedTimeRange(e.target.value)
    setShouldCount(true)
    setPage(1)
  }

  const formatFilterLabel = (filter) => {
    const { label } = tableHeaders.find((h) => h.id === filter.property)
    const condition = FilterConditionLabel[filter.condition]
    return `${label} ${condition} "${filter.displayValue}"`
  }

  const formatPagination = () => {
    let totalCount = totalDataCount
    if (limitCount) {
      totalCount = totalDataCount < limitCount ? totalDataCount : `${limitCount}+`
    }
    return `${dataIndexStart} to ${dataIndexEnd} of ${totalCount} results (${queryTime / 1000} seconds)`
  }

  const renderSkeleton = (count) => [...Array(count)].map(
    // Array index key is fine as the skeletons are a fixed length and order is not important
    // eslint-disable-next-line react/no-array-index-key
    (n, index) => <Skeleton key={index} height={50} style={{ margin: '20px' }} />
  )

  const isSelected = (id) => selected === id
  const isChecked = (id) => checked.indexOf(id) !== -1

  const handleCheckboxSelect = (event, value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
    onChecked(tableData.filter((e) => newChecked.includes(e[rowKey])))
  }

  const emptyRows = rowsPerPage - tableData.length
  const emptyRowElements = () => Array.from({ length: emptyRows }, (_, i) => (
    <TableRow key={`emptyRow-${i}`} className="empty-table-row">
      <TableCell style={{ border: 'none' }} key={`emptyCell-${i}`} colSpan={tableHeaders.length} />
    </TableRow>
  ))

  return (
    <>
      {filtering
        && (
          <TableFilterDialog
            setFiltering={setFiltering}
            filters={filters}
            handleAddFilter={handleAddFilter}
            headers={headers}
            tags={tags}
            tableCaption={tableCaption}
          />
        )}
      {exporting
        && <SpinnerIndeterminateModal message="Exporting. Please wait..." />}
      {/* Quick filter and refresh/export/rows */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '50px',
        width: '100%',
        padding: '0px 20px 0px 20px',
      }}
      >

        <div style={{ margin: '0px 10px 0px 10px', width: '400px', display: 'flex' }}>
          <TextField
            fullWidth
            sx={{ height: '50px' }}
            id="quick-search-box"
            label="Quick Search"
            inputRef={quickSearchRef}
            value={quickSearchText}
            onChange={(e) => setQuickSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmitQuickSearch()
              }
            }}
            InputProps={{
              endAdornment: (
                <>
                  {quickSearchText !== '' && (
                  <IconButton
                    style={{
                      padding: '5px',
                    }}
                    aria-label="cancel quick search"
                    onClick={handleCancelQuickSearch}
                    size="large"
                  >
                    <Cancel />
                  </IconButton>
                  )}
                  <IconButton
                    style={{
                      padding: '5px',
                    }}
                    aria-label="submit quick search"
                    onClick={handleSubmitQuickSearch}
                    size="large"
                  >
                    <Search />
                  </IconButton>
                </>),
            }}
          />

          {timeRangeKey
            && (
              <FormControl variant="outlined" sx={{ ml: '10px', width: '200px' }}>
                <Select
                  id="select-timeRange"
                  value={selectedTimeRange}
                  onChange={handleSetTimeRange}
                >
                  {timeRange.map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                      {' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
        </div>

        <div style={{ display: 'flex', height: '20px' }}>
          {refreshing
            && <CircularProgress size="24px" style={{ marginTop: '15px' }} />}
          <Tooltip placement="top" title="Refresh table data" style={{ height: '60px', width: '100px' }}>
            <Button aria-label="refresh" variant="text" color="primary" size="small" onClick={handleRefresh}>
              Refresh&nbsp;&nbsp;
              <i className="fas fa-sync-alt" />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Export to CSV">
            <Button
              aria-label="export to csv"
              color="primary"
              size="small"
              variant="text"
              style={{ height: '60px' }}
              onClick={handleExportToCsv}
            >
              Export&nbsp;&nbsp;
              <i className="fas fa-file-download" />
            </Button>
          </Tooltip>
          <FormControl>
            <Tooltip placement="top" title="Rows per page">
              <Select
                style={{ margin: '20px 20px 5px 20px', height: '25px' }}
                id="select-pagesize"
                value={rowsPerPage}
                variant="outlined"
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem style={{ fontSize: '0.9em' }} key={25} value={25}>25</MenuItem>
                <MenuItem style={{ fontSize: '0.9em' }} key={50} value={50}>50</MenuItem>
                <MenuItem style={{ fontSize: '0.9em' }} key={100} value={100}>100</MenuItem>
              </Select>
            </Tooltip>
          </FormControl>
        </div>
      </div>

      {/* Filter display */}
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px 0px 20px' }}>
        <div style={{ width: '36%' }}>
          {filters.map((filter, index) => (
            <Chip
              key={`${filter.property}${filter.condition}${filter.value}`}
              style={{ margin: '5px', width: 'auto' }}
              variant="contained"
              size="small"
              onDelete={() => handleDeleteFilter(index)}
              color="primary"
              label={formatFilterLabel(filter)}
            />
          ))}
          <Tooltip title="Add new table filter" style={{ display: 'inline-block', height: '60px' }}>
            <Button
              aria-label="add filter"
              variant="text"
              color="primary"
              size="small"
              onClick={() => setFiltering(true)}
            >
              Filter&nbsp;&nbsp;
              <i className="fas fa-plus-circle" />
            </Button>
          </Tooltip>
          {filters.length > 0

            && (
              <Tooltip title="Clear all table filters" style={{ display: 'inline-block', height: '60px' }}>
                <Button
                  aria-label="clear filters"
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleClearFilters}
                >
                  Clear Filters&nbsp;&nbsp;
                  <i className="fas fa-ban" />
                </Button>
              </Tooltip>
            )}
        </div>

        {/* Pagination and query time */}
        <StyledPagination
          page={page}
          disabled={refreshing}
          count={Math.ceil(totalDataCount / rowsPerPage)}
          color="primary"
          variant="outlined"
          onChange={handleChangePage}
        />
        <p style={{
          fontSize: 'small', height: '32px', width: '36%', textAlign: 'right',
        }}
        >
          {!loading && `Showing ${formatPagination()}`}
        </p>
      </div>

      {
        loading
          ? renderSkeleton(15)
          : (
            <div style={{ overflow: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
              <Table
                nightwatch={`${nightwatch}-table`}
                sx={{
                  width: '100%',
                }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <TableHead
                  headers={tableHeaders}
                  onRequestSort={handleRequestSort}
                  order={order}
                  checkboxSelectable={checkboxSelectable}
                  rowCount={tableData.length}
                  options={(
                    deletable || updatable || downloadable || !!customActions.length
                  )}
                />

                <TableBody>
                  {tableData
                    .map((row, index) => {
                      const isItemSelected = isSelected(row[rowKey])
                      const isItemChecked = isChecked(row[rowKey])
                      return (
                        <Row
                          row={row}
                          isItemSelected={isItemSelected}
                          isItemChecked={isItemChecked}
                          handleSelectRow={handleSelectRow}
                          handleCheckboxSelect={handleCheckboxSelect}
                          handleFilterIdLink={handleFilterIdLink}
                          handleFilterParentIdLink={handleFilterParentIdLink}
                          rowKey={rowKey}
                          statusKey={statusKey}
                          key={row[rowKey]}
                          index={index}
                          tableHeaders={tableHeaders}
                          onRowChangeCallback={onRowChangeCallback}
                          checkboxSelectable={checkboxSelectable}
                          deletable={deletable}
                          updatable={updatable}
                          downloadable={downloadable}
                          customActions={customActions}
                          disableArrayLinks={disableArrayLinks}
                        />
                      )
                    })}
                  {emptyRows > 0 && (
                    emptyRowElements().map((element) => element)
                  )}
                </TableBody>
              </Table>
            </div>
          )
      }
    </>
  )
}

PaginatedDataTable.propTypes = propTypes
PaginatedDataTable.defaultProps = defaultProps
export default PaginatedDataTable
