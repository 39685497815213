import React, { useState } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'
import CopyToClipboard from './CopyToClipboard'

const DIALOG_PROPERTIES = [
  {
    type: 'loginDetails',
    title: 'User Creation in Progress',
    message: 'An email will be sent to the user containing their login details.',
  },
  {
    type: 'resetPassword',
    title: 'Resetting Password',
    message: 'Copy the password, it will not be accessible after you close this window.',
  },
]

const getDialogProperties = (dialogType) => {
  const dialogInfo = DIALOG_PROPERTIES.find(({ type }) => dialogType === type)
  if (!dialogInfo) {
    throw new Error(`Unknown dialog type ${dialogType}`)
  }
  return dialogInfo
}

const propTypes = {
  dialogType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  tempPassword: PropTypes.string,
}

const defaultProps = {
  tempPassword: undefined,
}

export default function TempPasswordDialog({
  dialogType, open, callback, tempPassword,
}) {
  const [openDialog] = useState(open)
  const [showingFullPassword, setShowingFullPassword] = useState(false)

  const handleClose = () => {
    callback(false)
  }

  const dialog = getDialogProperties(dialogType)

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.message}
          </DialogContentText>
        </DialogContent>
        {dialog.type === 'resetPassword' ? (
          <Typography variant="h5" component="h3" style={{ marginLeft: '50px' }}>
            {
              showingFullPassword
                ? tempPassword
                : tempPassword.replace(/.*/g, '********')
            }
            <div>
              <Button
                variant="outlined"
                onClick={() => { setShowingFullPassword(!showingFullPassword) }}
              >
                { showingFullPassword ? 'Hide' : 'Show' }
              </Button>
              <CopyToClipboard>
                {({ copy }) => (
                  <Button
                    className="copy-to-clipboard-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => copy(tempPassword)}
                  >
                    Copy
                  </Button>
                )}
              </CopyToClipboard>
            </div>
          </Typography>
        ) : <div />}
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
TempPasswordDialog.defaultProps = defaultProps
TempPasswordDialog.propTypes = propTypes
