import React from 'react'
import LinkProductsProjectsScreen from './LinkProductsProjectsScreen'

const submitApi = async (
  datalabFacade,
  projectId,
  podId,
  { updatedLinks }
) => datalabFacade.linkProjectToProducts(projectId, updatedLinks, podId)

/**
 * Allows selecting products to be linked with a project
 * @param {object} props - Component props
 * @returns {React.JSX.Element} a LinkProductsScreen component
 */
export default function LinkProductsScreen(props) {
  return (
    <LinkProductsProjectsScreen
      target="project"
      targetPrimaryKey="projectId"
      targetLinkKey="projectId"
      counterpartLinkKey="productName"
      counterpartCollectionName="products"
      counterpartCollectionPrimaryKey="name"
      selectedLinksMessage="Analysts in this project will be granted read-only access to the products listed here"
      submitApi={submitApi}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

