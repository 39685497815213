import { getRuntimeConfig } from '../util/Config';
import axios from 'axios';
const runtimeConfig = getRuntimeConfig();

export default class DataService {

    async fetchData(accessToken, endpointUrl, params) {
        let config = {};
        if(accessToken){
            config = {
                params: params ? params : {},
                headers: {
                    'Authorization': "Bearer " + accessToken,
                    'Ocp-Apim-Subscription-Key': runtimeConfig.AZURE_APIM_SUBSCRIPTION_KEY
                }
            };
        }
        let response;
        try {
            response = await axios.get(
                endpointUrl,
                config
            )
        } catch (error) {
            //if no results just return empty array
            if (error.response && error.response.status === 404) {
                return [];
            }
            //throw back
            throw error;
        }
        return response.data;
    }

    /**
     * Fetch one object from the API
     * 
     * @param {*} accessToken An access token with the correct read scope 
     * @param {*} schema The schema to validate the object against
     * @param {*} endpointUrl The GET endpoint to fetch the object from
     * @param {*} params The query params 
     */
    async fetchOne(accessToken, endpointUrl, params) {
        const config = {
            params: params,
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Ocp-Apim-Subscription-Key': runtimeConfig.AZURE_APIM_SUBSCRIPTION_KEY
            }
        };
        let response;
        try {
            response = await axios.get(
                endpointUrl,
                config
            )
        } catch (error) {
            //throw back
            throw error;
        }

        let object = response.data;
        return object;
    }

    async postOperation(accessToken, endpointUrl, body) {

        let headers = {};
        if(accessToken){
            headers = {
                'Authorization': "Bearer " + accessToken,
                'Ocp-Apim-Subscription-Key': runtimeConfig.AZURE_APIM_SUBSCRIPTION_KEY
            }
        }
        let response = await axios.post(
            endpointUrl,
            body,
            {
                headers
            }
        );
        return response;
    }

    findById(id, data) {
        let found = data.find(row => row._id === id);
        return found;
    }

}
